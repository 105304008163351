import React from 'react';
import './WorkExperience.css';

const WorkExperience = () => {
    return (
        <div className="jumbotron">
            <div className='container'>
                <p className='publications-header'>WORK EXPERIENCE</p>

                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>

                    <div className="work-column">
                        <img src={require('./images/stanford.jpg')} />
                        <div className='work-view' style={{ backgroundColor: '#ffd9d9' }}>
                            <h5 style={{ color: '#6e0909' }}>2020-2023 · Research</h5>
                            <h2 style={{ color: '#6e0909' }}>Stanford School of Medicine</h2>
                        </div>
                    </div>

                    <div className="work-column">
                        <img src={require('./images/tcairemlogo.jpg')} />
                        <div className='work-view'>
                            <h5>2022-2023 · Research</h5>
                            <h2>Temerty Centre for AI Research & Education in Medicine</h2>
                        </div>
                    </div>

                    <div className="work-column">
                        <img src={require('./images/sickkids.jpg')} />
                        <div className='work-view' style={{ backgroundColor: '#d9f2ff' }}>
                            <h5 style={{ color: '#145573' }}>2020-2023 · Research</h5>
                            <h2 style={{ color: '#145573' }}>The Hospital for Sick Children</h2>
                        </div>
                    </div>

                    <div className="work-column">
                        <img src={require('./images/kritik.jpg')} />
                        <div className='work-view' style={{ backgroundColor: '#f5e3ff' }}>
                            <h5 style={{ color: '#350f4a' }}>2019-2020 · Software</h5>
                            <h2 style={{ color: '#350f4a' }}>Kritik</h2>
                        </div>
                    </div>

                    <div className="work-column">
                        <img src={require('./images/microsoft.png')} />
                        <div className='work-view' style={{ backgroundColor: '#ffe2db' }}>
                            <h5 style={{ color: '#801b08' }}>2019 · Software</h5>
                            <h2 style={{ color: '#801b08' }}>Microsoft Canada</h2>
                        </div>
                    </div>

                    <div className="work-column">
                        <img src={require('./images/tophat.png')} />
                        <div className='work-view' style={{ backgroundColor: '#ffe3ea' }}>
                            <h5 style={{ color: '#4d0b1b' }}>2018 · Software</h5>
                            <h2 style={{ color: '#4d0b1b' }}>Top Hat</h2>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default WorkExperience;
