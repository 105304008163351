import logo from './logo.svg';
import './App.css';
import NavBar from './components/NavBar';
import Publications from './components/Publications';
import Education from './components/Education';
import WorkExperience from './components/WorkExperience';

function App() {
  return (
    <div className="App">
      <NavBar />

      <div className='container'>

        <div className="white-page-shadow">
          <Education />
        </div>

        <div className="white-page-shadow">
          <Publications />
        </div>

        <div className="white-page-shadow">
          <WorkExperience />
        </div>

      </div>
      <br />
    </div>
  );
}

export default App;
